const posts = [
    {
        id: 1,
        title: "How I Made this Website",
        tag: "Project",
        description: `
            Have you always wanted to create your very own website but don't know
            where to begin? I wrote this short article to hopefully help you get
            started on that journey. 
        `
    },
    {
        id: 2,
        title: "Sekuro RED Mentorship Program",
        tag: "Experience",
        description: `
            Coming Soon
        `
    }
];

const getPosts = () => {
    return posts
}

export default getPosts